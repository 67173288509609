export enum EnumDiscount {
    'Percentage' = 145,
    'Fixed Discount' = 146,
    'Fixed Rent' = 147,
    'General' = 236,
    'PrePayment' = 237,
    'Rental' = 'Rental',
    'Merchandise' = 'Merchandise',
    'DefaultAccountHead' = 4,
    'DefaultAccruedDiscountAccount' = 15
}

export enum EnumMerchDiscount {
    'Percentage' = 334,
    'Fixed' = 335
}

export enum EMerchandise {
    'DefaultARAccount' = 1,
    'DefaultDiscountAccount' = 4,
    'DefaultAccountHead' = 7,
    'DefaultAllowance' = 10,
    'DefaultRefundAccount' = 12,
    'DefaultUncollected' = 13,
    'DefaultNonRefundAccount' = 16
}

export enum EMerchandisePacks {
    'DefaultARAccount' = 1,
    'DefaultDiscountAccount' = 4,
    'DefaultAccountHead' = 7,
    'DefaultAllowance' = 10,
    'DefaultRefundAccount' = 12,
    'DefaultUncollected' = 13,
    'DefaultNonRefundAccount' = 16

}

export enum ELOVTypes {
    'OperationalReports' = 1,
    'AccountingReports' = 2,
    'ManagementReports' = 3,
    'DiscountType' = 4,
    'InquirySource' = 5,
    'TaxAppliedFor' = 6,
    'MerchandiseInventoryType' = 7,
    'BillingCycle' = 8,
    "CustomSearchFields" = 107,
    "FieldSeparators" = 108,
    "UnitTypeFields" = 109,
    "AccountingType" = 123,
    "SizeDisplayFormat" = 128,
    "WebhookEvent" = 122,
    "WebhookProvider" = 129,
    "DailyAccrualRoundingOption" = 131,
    "ScheduledReportInterval" = 138,
    "ScheduledReportStatus" = 139,
    "ThirdPartyIntegrationsDripcampaign" = 143,
    "IntegrationSystems" = 145,
    'Inquiry Status' = 26,
    'Reservation Status' = 32,
    'Campaign Applied To' = 40,
    'Campaign Action' = 41,
    'Campaign Email Sender' = 43,
    'Campaign Email Send To' = 44,
    'Campaign SMS Send To' = 61,
    'Campaign Phone Call Assigned To' = 62,
    'Campaign Phone Call To' = 63,
    "CallRoute" = 146,
    "ScriptType" = 147,
    "ScriptSection" = 148
}


export enum EBillingTypes {
    'Annual' = 1,
    'BiAnnual' = 2,
    'Quarterly' = 3,
    'Monthly' = 4,
    '28 Days' = 5,
    'Weekly' = 6
}

export enum EMiscellaneousSettings {
    'TenantNameFormat' = 12,
    'EnvelopeSize' = 13,
    'PaperSize' = 14
}

export enum EProspects {
    'Prospect' = 1,
    'Reserve' = 2,
    'Rent' = 3
}

export enum ETax {
    'DefaultAccountsReceivable' = 1,
    'DefaultPrepaidAccount' = 14,
    'DefaultAccountsPayable' = 12,
    'DefaultRefundAccount' = 12,
    'DefaultNonRefundAccount' = 16,
    'DefaultAccountHead' = 8,
    'DefaultAllowanceAccount' = 10,
    'DefaultUncollectedAccount' = 13
}

export enum EUnitType {
    'DefaultAccountsReceivableAccount' = 1,
    'DefaultDiscountAccount' = 4,
    'DefaultDepositRefundAccount' = 12,
    'DefaultDepositNonRefundAccount' = 16,
    'DefaultDepositAccount' = 2,
    'DefaultAllowanceAccount' = 10,
    'DefaultRentAccount' = 3,
    'DefaultRentUncollectedAccount' = 13,
    'DefaultPrepaidRentAccount' = 14,
    'DefaultAccruedDiscountAccount' = 15,
    'DefaultRentRefundAccount' = 12,
    'DefaultRentNonRefundAccount' = 16,
    'DefaultRentAllowanceAccount' = 10,
}

export enum EPaymentOrigin {
    'Reservation' = 1,
    'Rental' = 2,
    'Merchandise' = 3,
    'MoveOut' = 4,
    'MakePayment' = 5,
    'Transfer' = 6,
    'ReInstate' = 7,
    'AuctionManager' = 8,
    'Refund' = 9,
    'SeperateMerchRefund' = 10
}

export enum EMerchandiseModuleTypes {
    'Orders' = 1,
    'Transfer' = 2,
    'Adjustment' = 3,
    'Receive' = 4
}

export enum EpropertyProtection {
    'DefaultAccountsReceivable' = 1,
    'DefaultRefund' = 12,
    'DefaultPrepaidAccount' = 14,
    'DefaultAccountsPayable' = 12,
    'DefaultNonRefund' = 16,
    'DefaultAccountHead' = 6,
    'DefaultAllowance' = 10,
    'DefaultUncollected' = 13
}

export enum EReprintRadio {
    'All' = '1',
    'Primary' = '2',
    'Alternate' = '3'
}


export enum EFeesCharges {
    'DefaultAccountHead' = 5,
    'DefaultAllowance' = 10,
    'DefaultUncollected' = 13,
    'Fixed' = 184,
    'Percentage' = 183,
    'DefaultAccountsReceivable' = 1,
    'DefaultRefund' = 12,
    'DefaultPrepaidFee' = 14,
    'DefaultNonRefund' = 16
}

export enum TransferTypes {
    'Inquiry' = 1,
    'Reservation' = 2
}

export enum ECategory {
    'DeleteFailReason1' = 'You cannot delete this Category as there are Units assigned to this Category. Please reassign them to other Category before attempting to delete again!',
    'DeleteFailReason2' = 'You cannot delete this Category as there are Unit Types assigned to this Category. Please reassign them to other Category before attempting to delete again!'
}

export enum ELetterAndNoticesTemplates {
    'Welcome_Letter' = 1,
    'No_Lock_Letter' = 2,
    'Double_Lock_Letter' = 3,
    'Change_of_Address_Notice' = 4,
    'Declined_Auto_CC_Letter' = 5,
    'Balance_Due_Letter' = 6,
    'Partial_Payment_Received_Letter' = 7,
    'Returned_Check_Notice' = 8,
    'Insurance_Cancellation_Letter' = 9,
    'Insurance_Termination_Notice' = 10,
    'Rent_Raise_Letter' = 11,
    'Transfer_Letter' = 12,
    'Move_Out_Letter' = 13,
    'Blank_Letter' = 14,
    'Late_Notice' = 15,
    'Gate_Lock_Notice' = 16,
    'Unit_Overlock_Notice' = 17,
    'Lien_Notice' = 18,
    'Auction_Notice' = 19,
    'Partial_Payment_Returned_Letter' = 20,
    'Credit_Card_Reversal_Letter' = 21,
    'Refund_Voucher' = 22,
    'Credit_Card_Expiration_Letter' = 23,
    'Auction_Uncollected_Notice' = 24,
    'Notice_of_cutting_lock' = 25,
    'Reservation_Letter' = 26,
    'Scheduled_move_out' = 27,
    'Invoice_letter' = 28,
    'Partial_PrePayment_Received_Letter' = 29,
    'Refund_Letter' = 30,
    'Lease_Agreement' = 31,
    'Auction_Proceeds' = 32,
    'GenerateLabel' = 33,
    'Automatic_Credit_Card_Form' = 34,
    'Military_Addendum' = 35,
    'Vehicle_Addendum' = 36,
    'Tenant_Information_Sheet' = 37,
    'Direct_Debit_Authorization_Form' = 38,
    'Insurance_Cancellation_Notice' = 39,
    'Payment_Receipt' = 40,
    'Merchandise_Campaign' = 41,
    'Insurance_Addendum' = 42,
    'Automatic_ACH_Form' = 46,
    'ACH_Reversal_Letter' = 48
}

export enum ETransactionTypes {
    "Move_In" = 1,
    "Payment" = 2,
    "Transfer" = 3,
    "Move_Out" = 4,
    "Merchandise" = 5,
    "Payment_Reversal" = 6,
    "Merchandise_Return" = 7,
    "Add_Charges" = 8,
    "Add_Allowance" = 9,
    "Change_Insurance" = 10,
    "Add_Notes" = 11,
    "Schedule_Move_Out" = 12,
    "Rent_Raise" = 13,
    "Print_Letters" = 14,
    "Reinstate_Unit" = 15,
    "Day_End" = 16,
    "Reservation" = 17,
    "Online" = 18,
    "Call_Center" = 19,
    "Modify_Lien_Stage" = 20,
    "Change_Due_Day" = 21,
    "Refund" = 22,
    "Apply_Discounts" = 23,
    "Stop_Fees_&_Discounts" = 24,
    "Invoice_Manually" = 25,
    "Quotation" = 26,
    "Inquiry" = 27,
    "Call_Center_Payment" = 28,
    "Call_Center_Inquiry" = 29,
    "Call_Center_Quotation" = 30,
    "Kiosk_Rental" = 31,
    "Kiosk_Payment" = 32,
    "Kiosk_Merchandise" = 33,
    "Online_Reservation" = 34,
    "Online_Rental" = 35,
    "Manual_Lien_Process" = 36,
    "Storage_Treasures" = 37,
    "IVR_Payment" = 38,
    "Process_Direct_Debit" = 39,
    "Call_Center_Rental" = 40,
    "Edit_Tenant_Info" = 41,
    "Process_Credit_Card" = 43,
    "Auction_Manager" = 45,
    "Cases" = 47,
    "Allowance_Reversal" = 55,
    'Scheduled_Report' = 57
}

export enum EActionTypes {
    "CREATE" = 1,
    "EDIT" = 2,
    "VIEW" = 3,
    "DELETE" = 4,
    "ANY" = 5
}

export enum EElementTypes {
    "BUTTON" = 1,
    "INPUT" = 2,
    "DIV" = 3,
    "LINK" = 4,
    "ANY" = 5
}

export enum ELookUpTask {
    "TaskUserfunctionId" = 410,
    "StatusLovTypeId" = 114,
    "PriorityLovTypeId" = 27,
    "All" = 0,
    "Inquiry" = 1,
    "Reservation" = 2,
    "Rental" = 3,
    "EveryDay" = 552,
    "EveryWeek" = 553,
    "EveryMonth" = 554,
    "EveryYear" = 555,
    "EndBy" = 556,
    "EndAfter" = 557,
    "NoEnd" = 558,
    "StatusOpen" = 549,
    "EndTypeNoEndDate" = 558,
    "MandatoryFormId" = 257
}

export enum EAuctionEligibleUnitsApprovalStatus { // LOV type 118
    'Approved' = 566,
    'Denied' = 567
}

export enum EApprovalsDMRM { // LOV type 124 125 126
    "Generic" = 602,
    "District" = 603,
    "Region" = 604,
    "Reversal" = 605,
    "Refund" = 606,
    "Approved" = 607,
    "Denied" = 608,
    "SentforApproval" = 609,
    "AllowanceReversal" = 635
}

export enum ECustomerDocMailingStatus { // LOV type 11
    'FirstClass' = 171,
    'CertificateofMailing' = 172,
    'CertifiedMail' = 173,
    'FirstClassPostcard' = 568,
    'CertificateofMailingPostcard' = 569
}

export enum ECustomSearch { // LOV type 119
    "OnlyforLoggedInFacility" = 573,
    "FacilitiesUserhasPermissionsFor" = 574,
    "AllFacilities" = 575,
    "WidthxLength" = 618
}


export enum EGateSystems { //Gate Systems
    "DigiGate" = 1,
    "Sentinel" = 2,
    "QuikStor" = 3,
    "PTI" = 4,
    "WHAM" = 5,
    "DoorKing" = 6,
    "GatEase!" = 7,
    "Axcys" = 8,
    "StorLogix" = 9,
    "OpenTech IOE" = 12,
    "Noke" = 13,
    "PTI Core: StorLogix Cloud" = 14,
    "StorGuard" = 16,
}

export enum ELOV_TransactionTypes { // LOV Type 47
    'Rental' = 318,
    'Merchandise' = 319,
    'Reservation' = 320,
}

export enum ELOV_LeaseStatus {
    'Moved_Out' = 327
}

export enum ELOV_UnitStatus {
    'Vacant' = 192,
    'Unavailable' = 193,
    'Company Unit' = 194,
    'Rented' = 195,
    'Reserved' = 196,
    'Pending Move-In' = 197,
}

export enum ELOV_ReservationUnitStatus {
    'ReservationRented' = 246, // reservation unit status 246 - Rented
}

export enum EUserFunctions {
    'Settings : Company' = 1,
    'Facility Info - Custom Fields' = 2,
    'Store Groups' = 3,
    'Office' = 4,
    'Facility Info' = 5,
    'News' = 6,
    'Competitor' = 7,
    'Billing Types' = 8,
    'Payment Modes' = 9,
    'Miscellaneous Settings' = 10,
    'Reservation Settings' = 11,
    'Settings : Online Reservation' = 12,
    'Move-In Settings' = 13,
    'Transfer Settings' = 14,
    'Move-Out Settings' = 15,
    'Lease Settings' = 16,
    'Payment Settings' = 17,
    'Facility Info - Credit Card' = 18,
    'Facility Info - Cash Register' = 19,
    'Facility Info - ACH' = 20,
    'Invoice Settings' = 21,
    'Rent Raise Settings' = 22,
    'Day End Settings' = 23,
    'Late & Lien Settings' = 24,
    'EFT Settings' = 25,
    'Settings : Accounting' = 26,
    'Mandatory Fields' = 27,
    'User Groups' = 28,
    'User' = 29,
    'Tax' = 30,
    'Fees & Charges' = 31,
    'Discounts' = 32,
    'Pricing Definition' = 33,
    'Merchandise' = 34,
    'Merchandise Packs' = 35,
    'Vendors' = 36,
    'Property Protection' = 37,
    'Marketing' = 38,
    'Cancellation Reasons' = 39,
    'Late and Lien' = 40,
    'Prospect Settings' = 41,
    'Credit Cards' = 42,
    'Categories' = 43,
    'Layout Dimensions' = 44,
    'Facility Info - Sections' = 45,
    'Building Areas' = 46,
    'Unit Types' = 47,
    'Facility Info - Units' = 48,
    'Facility Info - Gate Systems' = 49,
    'Walkthrough' = 50,
    'Late & Lien Schedule' = 51,
    'Account Settings' = 52,
    'Letters & Templates' = 53,
    'Set Auction Date' = 54,
    'Dashboard_Module9' = 55,
    'User - Change Password' = 56,
    'Competitor Rate' = 57,
    'Merchandise Inventory : Order' = 58,
    'Merchandise Inventory : Receive' = 59,
    'Merchandise Inventory : Transfer' = 60,
    'Merchandise Inventory : Audit/Adjustment - Add' = 61,
    'Open/Close Store' = 63,
    'Merchandise Inventory : Audit/Adjustment - Remove' = 64,
    'Rental' = 65,
    'Add Unit : Edit Deposit' = 66,
    'Add Unit : Edit Rent' = 67,
    'Reservation' = 70,
    'Rental : Bankruptcy' = 71,
    'Move Out' = 75,
    'Merchandise Sales' = 76,
    'Merchandise Return' = 78,
    'Reprint Receipts' = 79,
    'Dashboard' = 81,
    'Customer Info' = 82,
    'Modify Rental' = 83,
    'Rental : Exempt from Invoice Fee' = 84,
    'Rental : Exempt from Rent' = 85,
    'Rental : Exempt from Tax' = 86,
    'Rental : Opposition to Lien' = 87,
    'Rental : Exempt from Gate Lock' = 88,
    'Rental : Exempt from Overlock' = 89,
    'Rental : Exempt from Late Fee' = 90,
    'Rental : Exempt from Lien Charges' = 91,
    'Modify Rental : Add Fees & Charges' = 95,
    'Transfer' = 96,
    'Reversal' = 103,
    'Rentals : Reinstate Unit' = 104,
    'Batch Rent Raise' = 105,
    'Reprint' = 106,
    'Manual Auto Pay' = 107,
    'Letters & Notices' = 108,
    'Refund' = 109,
    'Layout' = 110,
    'Rent Change Settings' = 180,
    'Units with Discount' = 181,
    'Modify Rental : Apply/Remove Discounts' = 182,
    'Modify Rental : Stop Fees & Charges' = 183,
    'Automatic Unit Rent Change' = 184,
    'Invoice Manually' = 185,
    'Report Groups' = 189,
    'Period Settings' = 190,
    'Rental : Exempt from Rent Raise' = 191,
    'Email, SMS and Phone System Settings' = 192,
    'Auction Advertisement' = 193,
    'New Rental' = 194,
    'Competition Rates Comparison' = 195,
    'Discount' = 196,
    'Modify Rental : Lock/Unlock Unit' = 197,
    'Dashboard Settings' = 198,
    'Rental : Prorate In Unprorate' = 199,
    'Move Out : Prorate Out Unprorate' = 227,
    'Follow-Ups' = 230,
    'Rentals : Export all units to gate' = 237,
    'Auction Manager' = 247,
    'Unsigned Documents' = 248,
    'Prospect' = 251,
    'Add Unit : Offer Internet Price' = 253,
    'Rentals : Tie Rental to Inquiry/Reservation' = 256,
    'Budgets' = 257,
    'Close Store : Traffic Statistics' = 259,
    'Close Store : Skip Options' = 260,
    'Holidays' = 266,
    'Reversal : NSF Reversals' = 271,
    'Modify Rental : Rent Raise' = 274,
    'Rentals : Refund By Cash' = 276,
    'Rentals : Refund By Check' = 277,
    'Rentals : Refund By Credit Card' = 278,
    'Rentals : Edit Rent' = 290,
    'Rental : Exempt from Late and Lien Letters' = 291,
    'Rental : Auto CC Processing Day' = 292,
    'Merchandise Sales : Sell Tax Free' = 294,
    'Rental : Blacklist' = 296,
    'Rentals : Offline CC Transactions' = 303,
    'Attributes' = 313,
    'Reversal : Wrong Payment Cash Reversals - Same Day' = 314,
    'Reversal : Wrong Payment Check Reversals - Same Day' = 315,
    'Reversal : Wrong Payment Card Reversals - Same Day' = 316,
    'Reversal : Wrong Payment Cash Reversals - Other Day, Same Month' = 317,
    'Reversal : Wrong Payment Check Reversals - Other Day, Same Month' = 318,
    'Reversal : Wrong Payment Card Reversals - Other Day, Same Month' = 319,
    'Reversal : Wrong Payment Cash Reversals - Other Month' = 320,
    'Reversal : Wrong Payment Check Reversals - Other Month' = 321,
    'Reversal : Wrong Payment Card Reversals - Other Month' = 322,
    'Reversal : Misposting Reversals - Same Day' = 323,
    'Reversal : Misposting Reversals - Other Day, Same Month' = 324,
    'Reversal : Misposting Reversals - Other Month' = 325,
    'Call Recording' = 330,
    'Rental : Block Online Access' = 331,
    'Rental : Block IVR Payments' = 332,
    'Approve EFT' = 351,
    'EFT File Export' = 352,
    'Group Type' = 355,
    'Rental : Grace Days for Delinquency' = 356,
    'Revenue Plan' = 357,
    'Change Unit Status' = 360,
    'Move Out : Adjust Deposit Against Dues' = 375,
    'Move Out : Company Keeps Refund' = 376,
    'Ad Source' = 380,
    'Dispositions' = 381,
    'Rental Trucks' = 383,
    'Drip Campaign' = 384,
    'Batch SMS' = 390,
    'Rental : Exempt from Sending Details to One Local' = 399,
    'Call Recording - Download' = 400,
    'Call Recording - Email' = 401,
    'Customer - Attachments' = 402,
    'Letters & Notices - Schedule' = 403,
    'Letters & Notices - Email' = 404,
    'Tasks' = 410,
    'User and Groups' = 422,
    'User Group Permissions' = 423,
    'Allowance Items' = 424,
    'Customer - Cases' = 431,
    "DashboardEmailandSMSOption" = 433,
    'Inventory Adjustment Reasons' = 434,
    "Webhook Subscription" = 435,
    "User API Access" = 438,
    'Campaign Tracker' = 446,
    'Call Tracker' = 447,
    'Modify Rental : Rent Raise Letter' = 448,
    'Batch Rent Raise - Rent Raise Letter' = 449,
    'MFA Settings' = 469,
    'SWT Dashboard' = 470,
    'Tenant Mapping' = 472,
    'User Risk' = 474,
    'Prospect: Matching Contact' = 476,
    'Subscribe Reports' = 477,
    'Order Reports' = 478,
    'Export Reports' = 479,
    'Scheduled Reports - All Facilities' = 480,
    'Add Unit: Edit Rent with Limit' = 485,
    'Rentals: Edit Rent with Limit' = 486,
    'Call Script' = 487,
    'Notification Settings' = 492,
    'Opentech Viewport' = 493,
    'Missing CC Payments' = 494,
    'RingCentral' = 466
}

export enum ELOV_WebhookProvider { // LOV type 129
    "Rhythm" = 620,
    "BirdEye" = 621
}

export enum EOpenEdgeCancelationState {
    "None" = 0,
    "Requested" = 1,
    "Confirmed" = 2,
    "Failed" = 3
}

export enum EOpenEdgeSessionStates {
    "NotFound" = 0,
    "Initializing" = 1,
    "Processing" = 2,
    "Finalizing" = 3,
    "Canceling" = 4,
    "Completed" = 5,
    "PromptingForCard" = 6,
    "PromptingForCardRemoval" = 7,
    "ChipErrorPromptingForCardRemoval" = 8
}

export enum EPropertyProtectionStatus {
    'None' = 254,
    'Insured' = 255,
    'ProofOfInsurance' = 256,
    'SelfInsurance' = 257,
    'PendingInsurance' = 514
}

export enum EPropertyProtectionId {
    'None' = -1,
    'ProofOfInsurance' = -2,
    'SelfInsurance' = -3,
    'PendingInsurance' = -4
}

export enum ELOV_AccountingSoftware {
    "Yardi" = 447,
    "GreatPlains" = 448,
    "Sage200" = 449,
    "QuickBooks" = 450,
    "Sage300" = 617,
    "Sage100" = 633
}

export enum EPaymentTransactionTypes {
    "MoveIn" = 1,
    "Payment" = 2,
    "Transfer" = 3,
    "MoveOut" = 4,
    "Merchandise" = 5,
    "PaymentReversal" = 6,
    "MerchandiseReturn" = 7,
    "ProcessCreditCard" = 10,
    "Refund" = 11,
    "Reservation" = 12,
    "Reinstate" = 24,
    "AuctionManager" = 25
}

export enum EPaymentModes {
    "Cash" = 1,
    "Check" = 2,
    "CreditCard" = 3,
    "DirectDebitEFT" = 4,
    "HostedPage" = 5,
    "ACH" = 6,
    "Gift_Card" = 7,
    "ManualAuthorization" = 8,
    "CardOnfile" = 9,
    "ACHOnFile" = 10,
    "ACHManualAuthorization" = 11
}

export enum EPaymentGateway {
    "OpenEdgeExpress" = 209,
    "TdBank" = 208
}

export enum ECreditCards {
    "American_Express" = 1,
    "Visa" = 2,
    "Master_Card" = 3,
    "Discover" = 4,
    "Diners" = 5,
    "JCB" = 6
}
export enum EPaymentTransactionType {
    "Processed" = 357,
    "Declined" = 358,
    "Reversal_Refund" = 359
}

export enum ELOV_LimitOptions {
    "Reinstate" = 539,
    "AllowanceReversal" = 634,
    "AddionalPrePayPerid" = 699
}

export enum EEdgeExpressResultCode {
    "Success" = 0,
    "Canceled" = 3,
    "Fail" = 4,
    "NoDeviceConfigured" = 5,
    "DeviceNotConfigured" = 6,
    "DeviceInitError" = 7,
    "Declined" = 12,
    "Error_Reading_Chip" = 15
}

export enum ELOV_StoreOptions { // lov type 62
    "Store" = -1 * 351 // make id negative to avoid id conflict with users list when combined
}

export enum TaxAppliedFor {
    "CostComponent" = 155,
    "TaxComponent" = 156,
    "CostandTaxComponent" = 157
}

export enum EDashboardOptions {
    "Conversion_Activity" = 17,
    "PTI_ViewPort" = 18,
    "Inq_Conversion_Statistics" = 19,
    "Res_Conversion_Statistics" = 20

}

export enum ERefundCriteriaType {
    "FullCredit" = 328,
    "CreditGreaterThanAmount" = 329,
    "CreditGreaterThanPeriod" = 330
}

export enum EMerchandiseTransactionType {
    "MerchandiseSale" = 321,
    "MerchandiseReturn" = 322
}


export enum EACHPaymentGateway {
    "EdgeExpress" = 456,
    "TSYS" = 457
}

export enum EACHCheckResultCode {
    "Alias_Success" = '005',
    "Check_Submitted" = '011',
    "CheckApproval" = '012',
    "CheckDecline" = '013',
    "CheckWarning" = '014',
    "CheckError" = '015',
    "LimitExceeded" = '016',
    "Daily_Amount_Limit_Exceeded" = '017',
    "Monthly_Amount_Limit_Exceeded" = '018',
    "AlreadyVoided" = 815
}
export enum EReversalTypes {
    "WrongPayment" = 261,
    "NSF" = 262,
    "Misposting" = 263
}

export enum EQuestionType {
    "RadioOption" = 683,
    "Checkbox" = 684,
    "Text" = 685
}

export enum SWTAttachmentFileType {
    "Image" = 665
}

export enum PrimaryIdType {
    "DrivingLicense" = 540
}

export enum EEdgeExpressECIType {
    "Moto" = 1,
    "Recurring" = 2
}

export enum EOpenEdgeResponseCode {
    "Approval" = "000",
    "ZeroDollarAuthApproval" = "032",
    "ImproperFieldData" = 811,
    "TransactionDoesNotExist" = 814,
    "TransactionAlreadyVoided" = 815,
    "In_Progress" = -1
}

export enum EAllowanceTypes {
    "Allowance_Added" = 1,
    "Allowance_Cancelled" = 2
}

export enum EPresetValue {
    "Daily" = 1,
    "WeekToDate" = 2,
    "MonthToDate" = 3,
    "YearToDate" = 4,
    "Custom" = 5,
}

export enum EOperationalReports {
    "activityReport" = 1,
    "agedReceivables" = 2,
    "autoAchDues" = 3,
    "autoAchList" = 4,
    "automaticCreditCardDues" = 5,
    "automaticCreditCardsList" = 6,
    "cashDrawer" = 7,
    "declinedAchList" = 9,
    "declinedCreditCardsList" = 10,
    "discount" = 11,
    "emailLog" = 12,
    "exemptedTenants" = 13,
    "expiredCreditCardsList" = 14,
    "failedCreditCardsList" = 15,
    "followUps" = 16,
    "gateAccessList" = 17,
    "inquiries" = 18,
    "insuranceStatusReport" = 19,
    "letterSchedule" = 20,
    "lienStages" = 21,
    "merchandiseInventoryList" = 22,
    "merchandiseReorderLevel" = 23,
    "newInsurance" = 24,
    "newRental" = 25,
    "nonTaxedRentActivity" = 26,
    "occupantStatusList" = 27,
    "onelocalOptionSelectedByTenants" = 28,
    "onelocalTransaction" = 29,
    "pastDueTenantsList" = 30,
    "paymentReversals" = 31,
    "pendingMoveIns" = 32,
    "pendingRentChanges" = 33,
    "processedAchList" = 34,
    "processedCreditCardsList" = 35,
    "rentalChanges" = 37,
    "returnedChecks" = 38,
    "scheduledMoveOutsList" = 39,
    "tenantAddresses" = 40,
    "tenantBilling" = 41,
    "tenantDirectory" = 42,
    "tenantEmails" = 43,
    "tenantNotes" = 44,
    "unitActivities" = 45,
    "unitAttributes" = 46,
    "unitDetails" = 47,
    "unitInspection" = 48,
    "unitLockUnlockList" = 49,
    "unitPotential" = 50,
    "unitReinstateList" = 51,
    "unitStatus" = 52,
    "unitTypePriceList" = 53,
    "unitsWithDeposit" = 54,
    "unitsWithDiscount" = 55,
    "unitsWithInsurance" = 56,
    "unitsWithInvoice" = 57,
    "usclUpload" = 58,
    "userAuditTrail" = 59,
    "vacantUnitsList" = 60,
    "customerPortalRegistrations" = 203,
    "siteWalkthrough" = 240,
    "smsLog" = 459,
    "collectionCalls" = 469,
    "inquiryReminders" = 472,
    "reservationReminders" = 473,
    "phoneCalls" = 475,
    "activeContacts" = 476,
    "collectionCallContacts" = 478,
    "automaticCreditCardsListDateWise" = 479,
    "adSourceInquiriesAndReservations" = 481,
    "onlineRentals" = 482,
    "autoEftList" = 506,
    "vacantUnitsListII" = 544,
    "automaticCreditCardsListII" = 545,
    "auctionSchedule" = 572,
    "auctionSummary" = 578,
    "terminatedRentals" = 579,
    "newRentalII" = 584,
    "transactionLog" = 587,
    "declinedEftList" = 610,
    "smsClickToCallList" = 651,
    "smsClickToPayList" = 652,
    "smsClickToOptOutList" = 653,
    "undeliveredSmsList" = 654,
    "expiredProofOfInsurance" = 673,
    "expiringProofOfInsurance" = 674,
    "modifiedLienStagesDetails" = 675
}

export enum EAccountingReports {
    'accountsReceivable' = 61,
    'accountsReceivableII' = 62,
    'accruedRentReport' = 63,
    'accruedRentReportII' = 64,
    'bankDeposit' = 65,
    'chartOfAccounts' = 66,
    'collections' = 67,
    'creditCardDeposits' = 68,
    'creditCardSummary' = 69,
    'credits' = 70,
    'dailyCashReceiptsAccrual' = 71,
    'dailyCashReceiptsAccrualII' = 72,
    'dailyCashReceiptsCash' = 73,
    'dailyDepositSlip' = 74,
    'dayEnd' = 75,
    'detailedTrialBalance' = 76,
    'existingTenantsWithInsurance' = 77,
    'feesPaid' = 78,
    'insurancePastDueTenantList' = 79,
    'insurancePremiumPaymentSummary' = 80,
    'insurancePremiumsPaidByTenants' = 81,
    'insuranceSubmissionStatement' = 82,
    'inventoryActivity' = 83,
    'propertyProtectionPremiumCharged' = 84,
    'propertyProtectionPremiumRcvd' = 85,
    'merchandiseInventory' = 86,
    'merchandiseReturns' = 87,
    'merchandiseSales' = 88,
    'monthlyCashReceiptsAccrual' = 89,
    'monthlyCashReceiptsCash' = 90,
    'monthlyDepositSlip' = 91,
    'newRentalsWithInsurance' = 92,
    'refundDetails' = 93,
    'rentCollectionsReport' = 94,
    'rentalActivity' = 95,
    'salesJournal' = 96,
    'salesJournalII' = 97,
    'trialBalance' = 98,
    'uncollectedDues' = 99,
    'vacatesWhoHadInsurance' = 100,
    'prepayment' = 625,
    'discountDetail' = 547,
    'creditCardTransactions' = 548,
    'salesTaxAnalysis' = 571,
    'refunds' = 585,
    'cashReceipts' = 586,
    'cashAudit' = 598,
    'propertyProtectionTransactionLog' = 615,
    'unitMix' = 629,
    'merchandisePayments' = 697,
    'SalesTaxCollected' = 749,
}


export enum EManagementReports {
    'agingDues' = 101,
    'annualSummary' = 102,
    'automaticUnitRentChange' = 103,
    'automaticUnitRentRaise' = 104,
    'accumulatedWeeklyStoreReport' = 105,
    'competitorRatesAnalysis' = 107,
    'consolidatedInsuranceSummary' = 106,
    'dailyRanking' = 108,
    'dayEndStatistics' = 109,
    'demographicDetails' = 110,
    'futureIncome' = 112,
    'inquiryStatistics' = 113,
    'insuranceSummary' = 114,
    'longevity' = 115,
    'managementSummary' = 116,
    'managementSummaryHistory' = 117,
    'managementSummaryII' = 118,
    'managementSummaryIII' = 119,
    'marketing' = 120,
    'merchandiseAdjustmentDetails' = 121,
    'nonStandardRent' = 122,
    'occupancyStatistics' = 123,
    'occupancyStatisticsByCategory' = 124,
    'occupancyStatisticsByFloorwise' = 125,
    'occupancyHistory' = 126,
    'operationalVariance' = 127,
    'projectedDiscounts' = 129,
    'rentRoll' = 130,
    'rentRollII' = 131,
    'rentalActivitySummary' = 132,
    'rentalAnalysis' = 133,
    'rentalAnalysisII' = 134,
    'rentalInformation' = 135,
    'reservationDetails' = 136,
    'reservationExceptions' = 137,
    'reservationStatistics' = 138,
    'revenueAnalysis' = 139,
    'revenueAnalysisII' = 140,
    'rentalAnalysisIII' = 141,
    'vacantUnitsAging' = 142,
    'vacateAnalysis' = 143,
    'zipCodeAnalysis' = 144,
    'FollowupDetails' = 424,
    'reservationList' = 425,
    'reservationHistory' = 426,
    'annualSummaryII' = 427,
    'inquiryList' = 428,
    'missedMoveIn' = 429,
    'inquiryHistory' = 430,
    'agentLeadConversionStatistics' = 468,
    'inquiryAndReservationStatistics' = 470,
    'phoneCallConversionStatistics' = 471,
    'leadStatistics' = 474,
    'activeLeadConversionStatistics' = 480,
    'rentalActivityDetail' = 546,
    'insuredTenantRoll' = 477,
    'propertyProtectionProviderDetail' = 559,
    'rentRollIII' = 560,
    'propertyProtectionAnalysis' = 561,
    'managementSummary36Month' = 564,
    'prepaidARAnalysis' = 570,
    'arTenantRent' = 576,
    'rentRollNonStorage' = 577,
    'userAndGroups' = 580,
    'userGroupPermissions' = 581,
    'managementSummaryV' = 599,
    'userChangeAudit' = 36,
    'occupancyStatisticsII' = 626,
    'keyStatisticsQuarterly' = 627,
    'managementSummaryIV' = 630,
    'reservationReconciliation' = 631,
    'managementSummaryVI' = 632,
    'rentalDetail' = 637,
    'inquiryAndReservationNotes' = 638,
    'employeeActivity' = 639,
    'employeeSales' = 640,
    'rental' = 641,
    'financial' = 642,
    'insurableUnits' = 645,
    'managementSummaryHistoryII' = 646,
    'redBox' = 647,
    'leadFunnel' = 648,
    'facilitySummaryII' = 649,
    'managementSummaryVII' = 650,
    'keyStats' = 297,
    'snapShot' = 698,
    'paymentSummary' = 747,
    'inquiryReservationList' = 744,
    'campaignSummary' = 748,
    'FollowupSummary' = 746,
    'OperatingIncomeRentalAnalysis' = 758,
    'ConsolidatedDiscount' = 759
}

export enum EInterval {
    'Daily' = 686,
    'Weekly' = 687,
    'Monthly' = 688,
    'Quarterly' = 689,
    'Yearly' = 690,
    'Custom' = 691
}

export enum EInquiryStatus {
    'Open' = 220,
    'Confirmed' = 221,
    'Closed' = 222,
    'Reserved' = 224,
    'Rented' = 225,
    'Cancelled' = 226,
}

export enum EReservationStatus {
    'NewReservation' = 241,
    'Confirmed' = 242,
    'Pending' = 243,
    'Cancelled' = 244,
    'Expired' = 245,
    'Rented' = 246
}

export enum EProspectStatus {
    'ActiveInquiriesandReservations' = 211,
    'All' = 212,
    'ActiveInquiries' = 213,
    'ActiveReservations' = 214,
    'AllInquiries' = 700,
    'AllReservations' = 701
}

export enum ETenantNameFormat {
    "LastName_FirstName" = 174,
    "LastName_FirstName_MiddleName" = 175,
    "LastName_MiddleName_FirstName" = 178,
}


export enum EAccountingReports {
    "AccountingExport" = 698
}

export enum EFileTypes {
    "PDF" = 694,
    "Excel" = 695,
    "Document" = 696
}

export enum EBillingCycles {
    'Anniversary_Date' = 162,
    'First_of_Month' = 163
}

export enum EMFAType {
    'Email' = 670,
    'SMS' = 671,
    'AuthenticatorApp' = 672
}

export enum EReportPeriodPresets {
    'Daily' = 0,
    'Weekly' = 313,
    'Monthly' = 314,
    'Quarterly' = 315,
    'Yearly' = 316
}

export enum ThirdPartyCampaignActions {
    'Email' = 715,
    'SMS' = 716,
    'Call' = 717,
}

export enum LetterAndTemplateType {
    'PDF' = 200,
    'HTML' = 201,
    'SMS' = 202,
    'CALL' = 718,
}

export enum DripCampaignActions {
    'Email' = 288,
    'SMS' = 289,
    'FollowUpPhoneCall' = 290,
    'FollowUpEmail' = 291,
    'FollowUpSMS' = 292,
    'ThirdPartyIntegration' = 713,
}

export enum EACHAccountType {
    'Savings' = 278,
    'Checking' = 279
}

export enum EListOfValues {
    'Davinci' = 719,
    'StorageCollections' = 720
}
export enum EFunctions {
    '3rdPartyIntegration' = 484
}

export enum ECallScriptSections {
    'Prospect' = 734,
    'MatchingContact' = 735,
    'Inquiry' = 736,
    'Reservation' = 737,
    'Rental' = 738,
    'Payment' = 739,
    'Documents' = 740,
    'FollowUp' = 741,
}

export enum ECallScriptForms {
    'Prospects: New : Contact Info' = 123,
    'Prospects: New: Contact Info: Business Account' = 124,
    'Prospects: New : Marketing' = 125,
    'Prospects: New : Unit Info : Add Unit' = 126,
    'Prospects: Unit Info' = 118,
    'Prospect: matching Contact' = 262,
    'Prospect: Inquiry' = 263,
    'Prospect/Reservation/Rental: Notes' = 129,
    'Prospect/Reservation/Rental: Add Follow Up' = 131,
    'Prospect/Reservation/Rental: Edit Follow Up' = 130,
    'Prospect/Reservation/Rental : Demographyphy' = 264,
    'Prospect: Reservation' = 265,
    'Prospect: Rental' = 266,
    'Prospect: Rental: Merchandise' = 267,
    'Prospects: Rental: Customer Info' = 144,
    'Prospects: Rental: Customer Info: Business Account' = 146,
    'Prospects: Rental: Customer Info: Military Account' = 147,
    'Prospects: Rental: Customer Info : Restrictions' = 148,
    'Prospects: Rental: Customer Info: Portal Login Info' = 268,
    'Prospects: Rental: Customer Info: Vehicle Info' = 145,
    'Prospects: Rental: Customer Info: Alternate Contact' = 149,
    'Prospects: Rental: Rent Unit: Invoice Setting' = 150,
    'Prospects: Rental: Rent Unit: Info Sheet' = 152,
    'Prospect: Payment' = 269,
    'Prospect: Document' = 270,
    'Follow Up' = 242,
}

export enum ECallScriptRoutes {
    'Inbound' = 679,
    'Outbound' = 680
}

export enum ECallScriptTypes {
    'NewProspect' = 681,
    'ExistingProspect' = 682
}

export enum EDripCampaignComponentEnum {
    'No Ad Source' = -1,
    'Aggregator' = 154,
    'Internet' = 151,
    'Internet - Mobile' = 152,
    'Walk-in Kiosk ' = 150,
    'Web Chat' = 655,
    'Prospect' = 280,
    'Reservation' = 281,
    'Rental' = 282,
    'Move Out' = 287,
    'Collection Call' = 392,
    'Payment' = 703,
    'Lien Actions' = 704
}

export enum ECustomErrorCodes {
    'SleepingThread' = 800
}

export enum EeSignServiceEnum {
    'Rsign' = 563,
    'Esign' = 562
}

export enum EFollowUpAction {
    "PhoneCall" = 230,
    "Email" = 231,
    "SMS" = 232
}

export enum EFollowUpPriority {
    "Normal" = 227,
    "Medium" = 228,
    "High" = 229
}

export enum EFollowUpStatus {
    "Open" = 233,
    "Completed" = 234,
    "Cancelled" = 235
}

export enum ENotificationOptions {
    "Approvals" = 1,
    "FollowUp" = 2,
    "Tasks" = 3,
    "ScheduleMoveIn" = 4,
    "ScheduleMoveOut" = 5,
    "AuctionEligibleUnits" = 6,
    "UnitsToBeLocked" = 7,
    "UnitsToBeUnLocked" = 8,
    "AutoCreditCardDeclines" = 9,
    "InventoryReorder" = 10,
    "OnlineRentals" = 11,
    "OnlinePayments" = 12,
    "OnlineReservations" = 13,
    "OnlineInquiries" = 14,
    "RentRaiseAlerts" = 15,
    "CallCenterAlerts" = 16,
    "DayEndLettersInvoices" = 17,
    "DayEndReports" = 18,
    "FileReadyToDownload" = 19,
    "MailhouseErrors" = 20,
    "ScheduledReports" = 21,
    "ExpiredProofOfPP" = 22,
    "UnitsRemovedFromAuction" = 23
}

export enum EScheduledReportFilterType {
    "Active Subscribed Reports" = '1',
    "Ordered Reports" = '2',
    "Deleted Subscribed Reports" = '3',
    "All" = '4'
}

export enum EMandatoryFieldsFormId {
    "Rental: New : Contact Info" = 273,
    "Prospects: New : Contact Info" = 123,
    'Prospects: New: Contact Info: Business Account' = 124,
    'Rental: New: Contact Info: Business Account' = 274
}

export enum EReservationType {
    "SoftReservation" = 238,
    "Reservation" = 239
}

export enum IRateBasedOn {
    "BaseRent" = 536,
    "BudgetedRent" = 537,
    "StreetRate" = 538
}

export enum IRateOption {
    "Fixed" = 533,
    "IncreaseBy" = 534,
    "DecreaseBy" = 535
}

export enum IPricingType {
    "Dollars" = 198,
    "Percent" = 199
}

export enum ERentRaiseType {// LovType 74
    'Fixed' = 384,
	'Percentage' = 385,
    'Rent To' = 420,	// LovType 84
	'Rent to Standard Rent' = 421,	// LovType 84
	'Limit New Rent Variance above Standard Rent' = 422 // LovType	84
}

export enum ECondtionType {
    "BeforeNextBillingDate" = 705,
    "BeforePTD" = 298
}